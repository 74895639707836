import { graphql } from 'gatsby'
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa'

type Props = {
  data?: Queries.SocialLinkFragment | null
  layout: 'TEXT' | 'ICON'
}

export const SocialLink = ({ data, layout }: Props): JSX.Element => {
  const renderIcon = () => {
    switch (data?.socialType) {
      case 'Facebook':
        return <FaFacebook />
      case 'Instagram':
        return <FaInstagram />
      case 'LinkedIn':
        return <FaLinkedin />
      case 'Tiktok':
        return <FaTiktok />
      case 'Twitter':
        return <FaTwitter />
      case 'Youtube':
        return <FaYoutube />
      default:
        return null
    }
  }
  return (
    <a
      href={data?.url || undefined}
      target="_blank"
      rel="noreferrer"
      aria-label={data?.socialType || undefined}
    >
      {layout === 'ICON' && renderIcon()}
      {layout === 'TEXT' && data?.socialType}
    </a>
  )
}

export const SocialLinkFragment = graphql`
  fragment SocialLink on DatoCmsSocialLink {
    __typename
    id: originalId
    socialType
    url
  }
`
