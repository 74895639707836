import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, ElementType } from 'react'

import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?:
    | (
        | Queries.DirectoryFacultyStaffMemberFragment
        | Queries.DirectoryLeaderFragment
        | Queries.DirectoryCoachFragment
      )
    | null
  layout?: 'DIRECTORY' | 'SUBSECTION'
  headingLevel?: number
}

export const DirectoryPerson = ({
  data,
  layout,
  headingLevel = 3,
  ...props
}: Props): JSX.Element => {
  const Heading = `h${headingLevel}` as ElementType
  const Subheading = `h${headingLevel + 1}` as ElementType

  const styles = {
    person: css`
      padding: 1em 1.5em;
      background: rgba(0, 0, 0, 0.02);
    `,
    name: css`
      font-size: var(--fs-36);
      font-family: var(--ff-display);
      margin: 0;
      ${layout === 'SUBSECTION' &&
      css`
        font-family: var(--ff-body);
        font-size: var(--fs-21);
        font-weight: 500;
      `}
    `,
    title: css`
      font-family: var(--ff-body);
      font-size: var(--fs-15);
      color: ${colors.gray30};
      /* text-transform: uppercase; */
      font-weight: 550;
      margin: 0.75em 0 0.5em;
    `,
    educationList: css`
      list-style: none;
      padding: 0;
      margin: 0.75em 0 0;
      font-size: var(--fs-15);
    `,
    education: css`
      font-size: var(--fs-14);
      color: ${colors.gray45};
      font-weight: 375;
      margin: 0.5em 0;
      font-style: italic;
      line-height: 1.25;
    `,
  }
  return (
    <div
      css={styles.person}
      {...props}
    >
      <Heading css={styles.name}>{data?.name}</Heading>
      <Subheading css={styles.title}>{data?.title}</Subheading>
      {data?.education?.length ? (
        <ul css={styles.educationList}>
          {data?.education.map((education, i) => (
            <li
              css={styles.education}
              key={i}
            >
              {education?.degree}
              {education?.location && `, ${education?.location}`}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}

export const DirectoryPersonFragment = graphql`
  fragment DirectoryFacultyStaffMember on DatoCmsFacultyStaffMember {
    __typename
    id: originalId
    name
    title
    education {
      location
      degree
    }
  }
  fragment DirectoryLeader on DatoCmsLeader {
    __typename
    id: originalId
    name
    title
    education {
      location
      degree
    }
  }
  fragment DirectoryCoach on DatoCmsCoach {
    __typename
    id: originalId
    name
    title
    education {
      location
      degree
    }
  }
`
